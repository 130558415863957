import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  // transform(value: string, lines: number): string {
  //   const paragraphs = value.split('\n'); // Assuming the paragraphs are separated by newline characters
  //   const truncatedParagraphs = paragraphs.map(paragraph => {
  //     const linesArray = paragraph.split('\n');
  //     const truncatedLines = linesArray.slice(0, lines).join('\n');
  //     return truncatedLines;
  //   });

  //   return truncatedParagraphs.join('\n');
  // }
  transform(value: string, words: number): string {
    if (!value) return '';

    // Split the paragraph into words
    const wordArray = value.split(' ');

    // If the paragraph has fewer words than the specified limit, return the original paragraph
    if (wordArray.length <= words) {
      return value;
    }

    // Otherwise, truncate the paragraph and add ellipsis
    const truncatedArray = wordArray.slice(0, words);
    const truncatedString = truncatedArray.join(' ');

    return truncatedString + '...';
  }

}
