import { Component, Input } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Post } from '../../model/post.model';

@Component({
  selector: 'app-view-card',
  templateUrl: './detail-post-card.component.html',
  styleUrls: ['./detail-post-card.component.scss']
})
export class DetailPostCardComponent {
  postLike = true;
  disabled = true;
  viewImage:string = null;
  modalReference: any;
  @Input() displayStatusBadge: boolean  = true
  @Input() displayLikeAndComments: Boolean = true

@Input() post : Post;
  constructor(config: NgbModalConfig, private modalService: NgbModal) {
    config.size = 'xl';
    config.backdrop = 'static';
    config.centered = true
  }

  openModel(content,img) {
    this.modalReference = this.modalService.open(content)
    this.viewImage = img
  }
  closeModel() {
    this.modalReference.close();
    this.viewImage = null
  }
  images = ['assets/images/big-lightgallry/01.jpg','assets/images/big-lightgallry/02.jpg','assets/images/big-lightgallry/03.jpg']
}
