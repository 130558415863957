import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  action?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;
  public adminMenuCollapsed: boolean = false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      title: "Dashboards",
      icon: "home",
      type: "link",
      path: "/dashboard",
      active: true,
    },
    // {
    //   path: "/notifications",
    //   title: "Notifications",
    //   icon: "bookmark",
    //   type: "link",
    //   bookmark: true,
    // },
    {
      title: "Member",
      icon: "user",
      type: "link",
      path: "member",
      active: false,
    },
    {
      title: "Post",
      icon: "task",
      type: "sub",
      active: false,
      children: [
        {
          title: "Member Post",
          type: "sub",
          active: false,
          children: [
            {
              path: "/member-post/pending",
              title: "Pending",
              type: "link",
            },
            { path: "/member-post/active", title: "Active/Inactive", type: "link" },
            {
              path: "/member-post/cancelled",
              title: "Cancelled",
              type: "link",
            },
          ],
        },
        {
          title: "Member Maran Post",
          type: "sub",
          active: false,
          children: [
            {
              path: "/member-maran-post/pending",
              title: "Pending",
              type: "link",
            },
            { path: "/member-maran-post/active", title: "Active/Inactive", type: "link" },
            {
              path: "/member-maran-post/cancelled",
              title: "Cancelled",
              type: "link",
            },
          ],
        },
        {
          title: "Maran Post",
          type: "sub",
          children: [
            {
              path: "/maran-post/antim-yatra",
              title: "Antim Yatra",
              type: "link",
            },
            { path: "/maran-post/prayer", title: "Prayer", type: "link" },
          ],
        },
        {
          path: "/post/community-post",
          title: "Community Post",
          icon: "bookmark",
          type: "link",
        },
        // {
        //   path: "/post/karobari-post",
        //   title: "Karobari Post",
        //   type: "link",
        // },
        // {
        //   title: "Approval",
        //   icon: "post",
        //   type: "sub",
        //   active: false,
        //   children: [
        //     {
        //       path: "/approval/member-approval",
        //       title: "Member Post",
        //       type: "link",
        //     },
        //     {
        //       path: "/approval/maran-approval",
        //       title: "Maran Post",
        //       type: "link",
        //     },
        //   ],
        // },
        {
          path: "/post/comments",
          title: "Comments",
          icon: "post",
          type: "link",
          bookmark: true,
        },
      ],
    },
    {
      title: " Verification",
      icon: "user",
      type: "sub",
      active: false,
      children: [
        {
          path: "/verification/payment-approval",
          title: "Payment & Approval",
          type: "link",
        },
      ],
    },
    {
      path: "/feedback-management/feedback",
      title: "Feedback Management",
      icon: "chat",
      type: "link",
      bookmark: true,
    },
    {
      title: "CMS",
      icon: "user",
      type: "sub",
      active: false,
      children: [
        {
          path: "/cms/contact-management",
          title: "Contact Management",
          type: "link",
        },
        {
          path: "/cms/about-community",
          title: "About Community",
          type: "link",
        },
        {
          path: "/cms/sanstha",
          title: "Sanstha",
          type: "link",
        },
        {
          path: "/cms/emergency-contact-number",
          title: "Emergency Contact Number",
          type: "link",
        },
        { path: "/cms/community-team", title: "Community Team", type: "link" },
        {
          title: "Policies",
          type: "sub",
          children: [
            {
              path: "/policies/privacy-policy",
              title: "Privacy Policy",
              type: "link",
            },
            {
              path: "/policies/terms-conditions",
              title: "Terms & Conditions",
              type: "link",
            },
          ],
        },
        { path: "/cms/faq", title: "FAQ", type: "link" },
      ],
    },
    {
      title: "Admin",
      icon: "icons",
      type: "sub",
      action: true,
    },
  ];

  ADMINMENU: Menu[] = [
    {
      title: "Admin Dashboard",
      icon: "home",
      type: "link",
      path: "/dashboard",
      active: true,
    },
    {
      title: " Staff Management",
      icon: "user",
      type: "sub",
      active: false,
      children: [
        { path: "/staff-management/roles", title: "Roles", type: "link" },
        { path: "/staff-management/users", title: "User", type: "link" },
      ],
    },
    // {
    //   title: "Tag Group",
    //   icon: "project",
    //   path: "/tag-management/tag-group",
    //   type: "link",
    //   active: false,
      // children: [
      //   // { path: "/tag-management/tag-group", title: "Tag group", type: "link" },
      //   { path: "/tag-management/tags", title: "Tags", type: "link" },
      // ],
    // },
    {
      title: "UX Setting",
      icon: "project",
      type: "sub",
      active: false,
      children: [
        {
          path: "/ux-setting/social-handles",
          title: "Social Handles",
          type: "link",
        },
        {
          path: "/ux-setting/logo-setup",
          title: "Logo Setup",
          type: "link",
        },
      ],
    },
    // {
    //   title: " Reports",
    //   icon: "charts",
    //   type: "link",
    //   path: "/Reports",
    //   active: true,
    // },
    // {
    //   path: "/Global-setting",
    //   title: "Global Setting",
    //   icon: "user",
    //   type: "link",
    //   bookmark: true,
    // },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  adminItems = new BehaviorSubject<Menu[]>(this.ADMINMENU);
}
