<ng-template #viewModal>
    <div class="modal-header">
        <h6 class="modal-title" id="modal-basic-title">{{ title }}</h6>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeModel()"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="model-view-image">
                <img [src]="viewImage" alt="" class="" width="100%" height="100%" style="object-fit: contain;">
            </div>
        </div>
    </div>
</ng-template>

<!-- <div class="card preventive-card mb-2 h-100" *ngFor="let tableList of tableList; index as k"> -->
<div class="card preventive-card mb-2 h-100">
    <div class="card-header pt-2 pb-1 px-2">
        <div class="row">
            <div class="col d-flex">
                <div class="col-auto" style="z-index: 2;">
                    <div class="profile-box">
                        <img src="https://media.istockphoto.com/id/1405522719/photo/man-sitting-indoor-looks-at-camera-participate-in-live-videoconference.webp?b=1&s=170667a&w=0&k=20&c=Dt8386bRyLdIZs_m586jKfbR3RaZ2M6evqqOnADCz-k="
                            alt="" width="100%" height="100%" style="object-fit: cover;">
                    </div>
                </div>
                <div class="ms-2 col-auto" style="z-index: 2;">
                    <div class="user-name"><strong>Ajay</strong></div>
                    <div class="d-flex"><small>25/11/2023 08:30 AM</small></div>
                </div>
            </div>
        </div>
    </div>
    <div class=" card-body pt-2 pb-1 px-2">
        <!-- ================ Title / Description Section ================ -->
        <div><strong>Feedback</strong></div>
        <div class="text-secondary">
            <small class="post-text-content">{{ tableList.description }}</small>
        </div>
    </div>
</div>