<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">

        <div>
          <div>
            <a class="logo" routerLink="/">
              <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="loginpage" style="width: 10%;"/>
              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="loginpage" />
            </a>
          </div>


          <div class="login-main1 h-100">
            <div class="row d-flex">
              <div class="col-md-6 d-none d-md-block image">

              </div>

              <div class="col-md-6 d-flex p-5 justify-content-center">
                <form class="theme-form" [formGroup]="loginForm">
                  <h3 class="d-flex justify-content-center heading"> Sign in to account</h3>
                  <p class="d-flex justify-content-center ">Enter your email & password to login</p>
                  <div class="form-group">
                    <label class="col-form-label">Email Address</label>
                    <input class="form-control" type="email" required="" placeholder="app@yourmail.com"
                      formControlName="email" />
                    <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
                      class=" text-danger mt-1">Email is required</div>
                    <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
                      class=" text-danger mt-1">Invalid Email</div>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password"
                      required="" placeholder="*********" />
                    <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                    <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                    <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
                      class="text-danger mt-1">Password is required</div>
                  </div>
                  <div class="form-group mb-0">
                    <!-- <div class="checkbox p-0">
                      <input id="checkbox1" type="checkbox" />
                      <label class="text-muted" for="checkbox1">Remember password</label>
                    </div> -->
                    <!-- <a [routerLink]="'/authentication/forgot-password'" class="link">Forgot password?</a> -->
                    <div class="text-end mt-5">
                      <button class="btn btn-lg rounded-5 d-block w-100" id="loginbtn" [disabled]="!loginForm.valid" (click)="login()"
                        type="button"><span>Sign in</span></button>
                    </div>
                  </div>
                  <!-- <h6 class="text-muted mt-4 or">Or Sign in with</h6> -->
                  <!-- <div class="social mt-4">
                    <div class="btn-showcase">
                      <a class="btn btn-light" target="_blank"> <app-feather-icons [icon]="'log-in'"
                          class="txt-google"></app-feather-icons>Google + </a>
                      <a class="btn btn-light" target="_blank"> <app-feather-icons [icon]="'facebook'"
                          class="txt-fb"></app-feather-icons>Facebook </a>
                      <a class="btn btn-light" target="_blank"> <app-feather-icons [icon]="'twitter'"
                          class="txt-twitter"></app-feather-icons>twitter </a>
                    </div>
                  </div>  -->
                  <p class="mt-4 mb-0 d-flex justify-content-center">
                    <span class="text1"> Don't have account? </span>
                    <a [routerLink]="'/authentication/register/simple'" class="ms-2">Sign Up</a>
                  </p>
                  <div class="col-md-12 text-center position-relative">
                    <div class="position-absolute w-100 text-center">
                      <p class="text-danger">{{ errorMessage }}</p>
                    </div>
                  </div>

                </form>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>